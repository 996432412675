import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import { OptimizedImage } from '../../components/common/OptimizedImage';

export default () => (
  <MainLayout withTitle>
    <div className="faq-wrapper">
      <div className="faq-content-wrapper">
        <div className="hdr text-left">
          <h2 className="mgn-bot-30">Cancel Subscription</h2>
          <p className="qnA-text">
            You can easily cancel your orders and you will not receive another
            order from us. If you are wanting to unsubscribe and never receive
            another order again then the cancel option is correct for you. If
            you just want to stop your orders for a while (perhaps if you are
            going on holiday) then the pause button would be a better option for
            you. You can find information on this within our FAQs.
          </p>
          <p className="mgn-top-30 qnA-text">
            1. Log in to your account and view your Dashboard <br />
            2. Click on "Manage Orders" <br />
            3. Manage your orders
          </p>
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="login.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="manage_order.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="cancel_subs.png" />
        </div>
        <p className="qnA-text">
          We do ask that you can fill in a few words as to why you are
          cancelling your order so we can work on improving for other customers.
          We will be sad to see you go but thank you for giving Feed My Furbaby
          a shot!
        </p>
        <p className="qnA-text mgn-top-50">
          If you didn't find the answer you were looking for please contact us,
          we would love to help! <br />
          Email: info@feedmyfurbaby.co.nz <br />
          Phone: 09 88 MYPET (09 886 9738) <br />
          Facebook: @myfurbabynz <br />
          Or contact us using our webchat.
        </p>
      </div>
    </div>
  </MainLayout>
);
